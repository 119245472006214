import '.././node_modules/bootstrap/dist/css/bootstrap.min.css';
import React, {  } from 'react';
import {Button,Modal,Accordion,Card,Table ,Badge,Image,Row,Col,Container} from 'react-bootstrap'; 

import './Style.css'
import pic1 from './Pictures/3D1.jpg';
import pic2 from './Pictures/3D2.jpg';
import pic3 from './Pictures/3D3.jpg';

import D1 from './Pictures/2D1.jpg';
import D2 from './Pictures/2D2.jpg';
import D3 from './Pictures/2D3.jpg';

import com1 from './Pictures/Com1.jpg';
import com2 from './Pictures/Com2.jpg';
import com3 from './Pictures/Com3.jpg';
import Art from './Pictures/Art.jpg';


import cov1 from './Pictures/cov1.jpg';
import cov2 from './Pictures/cov2.jpg';
import gal1 from './Pictures/gal.jpg';
import gal from './Pictures/gal1.jpg';
import gal2 from './Pictures/gal3.jpg';
import gal3 from './Pictures/gal2.jpg';
import gal4 from './Pictures/gal4.jpg';
import Center from './Center';

import ani from './Pictures/ani.jpg';
import ani1 from './Pictures/ani1.jpg';

function Exmaples() {
   
   
   
 
    return(

<>


<div>
<hr></hr>


<h1>My Art Exmaples</h1>
</div>
 <div>
    <h2 style={{ fontFamily : "cursive"}}>2D VTUBER MODELS</h2>
 <Container>
      <Row>
        <Col xs={6} md={4}>
          <Image src= {D1} thumbnail />
        </Col>
        <Col xs={6} md={4}>
          <Image src= {D2} thumbnail />
        </Col>
        <Col xs={6} md={4}>
          <Image src= {D3} thumbnail />
        </Col>
      </Row>

    </Container>
    <hr></hr>

        <h1 style={{ fontFamily : "cursive"}}>3D VTUBER MODELS</h1>
        <Container>
      <Row>
        <Col xs={6} md={4}>
          <Image src= {pic1} thumbnail />
        </Col>
        <Col xs={6} md={4}>
          <Image src= {pic2} thumbnail />
        </Col>
        <Col xs={6} md={4}>
          <Image src= {pic3} thumbnail />
        </Col>
      </Row>

    </Container>
        <div>
            <h1 style={{ fontFamily : "cursive"}} >  - ILLUSTRATION -</h1>
        <Image src={Art} fluid />

        </div>


 </div>
 <hr></hr>

<div >
    <h1 style={{ fontFamily : "cursive"}} > - COMICES ~ MANGA - </h1>
    <Container  >
      <Row style={{margin: 'Auto'}} >
        <Col xs={6} md={4}>
          <Image src= {com1} thumbnail />
        </Col>
        <Col xs={6} md={4}>
          <Image src= {com2} thumbnail />
        </Col>
        <Col xs={6} md={4}>
          <Image src= {com3} thumbnail />
        </Col>
      </Row>

    </Container>

</div>
<hr>
</hr>
<h1 style={{ fontFamily : "cursive"}} >-PNG Models-</h1>
    <Container  >
      <Row style={{margin: 'Auto'}} >
        <Col xs={6} md={10}>
          <Image src= {ani} thumbnail />
        </Col>
        <Col xs={5} md={10}>
          <Image src= {ani1} thumbnail />
        </Col>
      </Row>

    </Container>
<hr></hr>
<div className='container'>
    <h1 className='title'style={{ fontFamily : "cursive"}} >-VTUBER BACKGROUND-</h1>
    <div className='photo-gallery'>
        <div className='column'>
            <div className='photo'>
            <img src={cov1}></img>
            </div>
            <div className='photo'>
            <img src={cov2}></img>
            </div>
        </div>
    </div>
</div>

<hr></hr>






<div className='container'>
    <h1 className='title'style={{ fontFamily : "cursive"}} >-Art Gallery-</h1>
    <div className='photo-gallery'>
        <div className='column'>
            <div className='photo'>
            <img src={pic1}></img>
            </div>
            <div className='photo'>
            <img src={cov2}></img>
            </div>
            <div className='photo'>
            <img src={pic3}></img>
            </div>
        </div>
        <div className='column'>
            <div className='photo'>
            <img src={gal4}></img>
            </div>
            <div className='photo'>
            <img src={pic2}></img>
            </div>
            <div className='photo'>
            <img src={gal2}></img>
            </div>
        </div>
        <div className='column'>
            <div className='photo'>
            <img src={cov1}></img>
            </div>
            <div className='photo'>
            <img src={gal1}></img>
            </div>
            <div className='photo'>
            <img src={com3}></img>
            </div>
        </div>
    </div>
</div>

<hr></hr>



</>

        )
}
export default Exmaples