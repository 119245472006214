function Pgcenter() {

    return(


        <div>

            <h1><center>SomeThing Went Wrong</center></h1>
            <h2><center>Please Go To <a href="/"> Home Page</a></center></h2>
        </div>

      
    )
    
}
export default Pgcenter