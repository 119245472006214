import Header from './Header'
import Footer from './Footer'
import Helmet from "react-helmet"
function Services() {
   
    return(
        
        <div>
            <Helmet>
<title>Term of Services </title>
        </Helmet>
             <Header/>
               
     <h1 style={{textAlign:"center"}}> TERM OF SERVICE </h1>
       
<p>
By commissioning me, you agreed to all the terms listed below regarding both personal and commercial usage of the commission even if you don't read this TOS. Any inquiries please send to email.
-contact at-
Design@OzumiYashoda.ioslift.com
</p>
<hr></hr>
<h1 style={{textAlign:"center"}}> ENGAGEMENT</h1>

<p>
Client will provide clear details, reference sheets, assets or anything required for the commission. If the commission will be printed then the specs must be provided for physical reproduction.
The character design must be legally obtained, or client have the authorization to commission art of.
Unless stated otherwise, it's assumed that commission are for personal usage. Commercial usages have different rules and additional costs.
</p>
<hr></hr>


<h1 style={{textAlign:"center"}}> RIGHTS USAGE</h1>

<h5>Artist Rights : </h5>
<p>
The artist retain the copyright to display and distribute the commission both personal or commercial on their porfolio, in digital and/or print format, on social medias, or stream on any streaming platform. However, the client can ask for it to not be posted on social medias or stream if they wish.
The artist have natural rights to post WIPS on social medias if they wish. The client can ask for it not be posted but they must make it clear at the start.
If it was not verbalized the it's assumed the client allow WIPs and finished commission to be posted. There's no non-disclosure fee if the client request for the work to be privated.
</p>
<h5>Client Rights : </h5>
<p>
The client cannot claim credit, copy, trace the commission as their own work and artist must be credit if the commission is posted on social media.
Client cannot transfer or resell the work to another indivitual.
The client receive exclusive usage rights as they wish for personal works. This usage right doesn't apply for commercial commissions (such as vtuber assests, models, stream assets, or any attempt to monetize the commissions in merchandise) since it have different rules.
Commercial commissions will have additional costs and the rates will be listed below.
[ Alteration or reproduction of artworks for NFT, blockchains, machine learning software, and AI art programs of any kind is prohibited. Violation of this will result in blacklist and forfeit of all usage rights.]

</p>
<hr></hr>
<h1 style={{textAlign:"center"}}>CONTENT</h1>
<p>
Artist will not create explicit NSFW/pornographic, inappropriate, or offensive works. Absolutely no furries, gore, and requests that ask to copying another artist style.
Some restrictions might apply for underage characters, mechas, or requests that might trigger certain phobias. There might be additional charges for mecha or difficult commission requests that artist isn't familiar with. If you think your requests are these then please inquire in emails if the commission is possible.
Artist have the right to turn down commissions they're not comfortable with for whatever reasons.
</p>
<hr></hr>
<h1 style={{textAlign:"center"}}>COMMUNICATION</h1>
<p>
Client is guarantee ongoing commission with WIP update for client's approval and if there are any revision needed. The updates will be during the sketch approval phase, and one after the finished lineart and color planning phase so client can have idea of what the finished render might look like.
The client will also receive instant IRL update as fast as possible if any situation come up that can affect the commission process.
Communication over email is preferred for organization but if social media DMs are quicker then the artist can adjust according to the client's needs.
Artist's communication speed is usually in hours or same day at longest. However, if something happen like disaster, power outages, or anything that can prevent the artist from connecting online then the artist will try to update with communication as fast as she can so the client can be aware of the situation.
Final deliveries will be through email and file transfer like Google Drive.
<hr></hr>
</p>
<h1 style={{textAlign:"center"}}>REVISIONS</h1>
<p>
Minor revisions, like missing accessories, adjust some colorings, adding small details, are allowed without additional fees throughout the WIP updates and on the finished commission turn in. It's client's responsiblities to look over the WIP to check if there's any mistakes or details they want changed.
Major revisions are acceptable during sketch and lineart update but not allowed on finished commission.
If the client need to make a big changes or excessive changes then additional fees might apply.
</p>
<hr></hr>
<h1 style={{textAlign:"center"}}>TIMELINE</h1>
<p>
Timeline depend heavily on the type of commissions, the complexity, and current workload. Vtuber models will take longer than illustration. The more complex and detailed the commission is, the longer it will take.
Work completion are for standard illustrations is usually around one week as soon as the sketch is approve. Allow artist one, and at most two weeks, for completion of the requested commission for regular illustrations. If it takes any longer then additional updates will be provided to the client.
Client have the right to request refund if the commission is taking longer than one month WITHOUT any communication or WIP updates.
If there's a deadline for events or streams then please plan accordingly. There's additional rush fee of 20% if the commission is needed under 1 week.
</p>
<hr></hr>
<p> For Further Details of Pricing & Character Design DM me on Instagram @ozumiyashoda </p>

<Footer/>
        </div>
    )
} export default Services