
import './App.css';
import ReactGa from 'react-ga'
import { useEffect } from 'react';
import HomePage from './HomePage'
import About from './About';
import HttpsRedirect from 'react-https-redirect';
import PagenotFound from './PageNotFound/Pagenotfound';
import Term from './Services';
import {

 

  BrowserRouter as Router,
  Routes,
 Route

} from 'react-router-dom'



function App() {
//UA-81746914-4
  useEffect(()=>{
ReactGa.initialize('UA-81746914-4')
ReactGa.pageview(window.location.pathname + window.location.search)
},[])

  return (
    <>

<HttpsRedirect>

<Router>
    <Routes>
        <Route exact path="/" caseSensitive={false} element={<HomePage />} ></Route> 
        <Route  path="/about"  caseSensitive={false}  element={<About />} ></Route>
        <Route  path="/term-of-service"  caseSensitive={false}  element={<Term />} ></Route>
        <Route exact path="*"  caseSensitive={false} element={<PagenotFound />} ></Route>
    </Routes>
</Router>                     
</HttpsRedirect>



 




     

  
     
  
    </>
  );
}

export default App;
