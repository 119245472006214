
import React from 'react';


import {Image,Alert} from 'react-bootstrap'
import mac from './img/logowebsite.png';
import Helmet from "react-helmet"

function Center() {
    return(
      
        
 <div style={{backgroundColor: '#F8F8F8'}}  class="inner">
    <Helmet>
 
 <title>Ozumi Yashoda - Desginer</title>
 
 
        
         </Helmet>

<div>
<Image src={mac} fluid />
</div>
<Alert variant="success">
      <Alert.Heading>Hey there! Welcome to my website!</Alert.Heading>
      <p>
       I'm Ozumi Yashoda, a friendly and creative graphic designer. I'm all about building a community and helping new VTubers shine in the virtual world. Join me on this exciting journey of creativity and support, where we'll bring your virtual persona to life and create an amazing community together!🎨
      </p>
      <hr/>
    </Alert>

{/* imgage yahan Ayegi  */}

</div>


    )
}

export default Center;